@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;

@include mat.core();

$portal-blue: (
  50: var(--color-blue-50),
  100: var(--color-blue-100),
  200: var(--color-blue-200),
  300: var(--color-blue-300),
  400: var(--color-blue-400),
  500: var(--color-blue-500),
  600: var(--color-blue-600),
  700: var(--color-blue-700),
  800: var(--color-blue-800),
  900: var(--color-blue-900),
  contrast: (
    50: var(--color-gray-600),
    100: var(--color-gray-600),
    200: var(--color-gray-600),
    300: var(--color-gray-600),
    400: var(--color-gray-600),
    500: var(--color-white-DEFAULT),
    600: var(--color-white-DEFAULT),
    700: var(--color-white-DEFAULT),
    800: var(--color-white-DEFAULT),
    900: var(--color-white-DEFAULT),
    A100: var(--color-gray-600),
    A200: var(--color-gray-600),
    A400: var(--color-gray-600),
    A700: var(--color-gray-600)
  )
);

// Custom colors derived from Portal Style Guide 2021. TODO: Reference tailwind's colors directly here for consistency
$portal-red: (
  50: var(--color-red-50),
  100: var(--color-red-100),
  200: var(--color-red-200),
  300: var(--color-red-300),
  400: var(--color-red-400),
  500: var(--color-red-500),
  600: var(--color-red-600),
  700: var(--color-red-700),
  800: var(--color-red-800),
  900: var(--color-red-900),
  contrast: (
    50: var(--color-gray-600),
    100: var(--color-gray-600),
    200: var(--color-gray-600),
    300: var(--color-gray-600),
    400: var(--color-gray-600),
    500: var(--color-white-DEFAULT),
    600: var(--color-white-DEFAULT),
    700: var(--color-white-DEFAULT),
    800: var(--color-white-DEFAULT),
    900: var(--color-white-DEFAULT),
    A100: var(--color-gray-600),
    A200: var(--color-gray-600),
    A400: var(--color-gray-600),
    A700: var(--color-gray-600)
  )
);

$agent-portal-blue: (
  50: var(--color-blue-50),
  100: var(--color-blue-100),
  200: var(--color-blue-200),
  300: var(--color-blue-300),
  400: var(--color-blue-400),
  500: var(--color-blue-500),
  600: var(--color-blue-600),
  700: var(--color-blue-700),
  800: var(--color-blue-800),
  900: var(--color-blue-900),
  contrast: (
    50: var(--color-gray-600),
    100: var(--color-gray-600),
    200: var(--color-gray-600),
    300: var(--color-gray-600),
    400: var(--color-gray-600),
    500: var(--color-white-DEFAULT),
    600: var(--color-white-DEFAULT),
    700: var(--color-white-DEFAULT),
    800: var(--color-white-DEFAULT),
    900: var(--color-white-DEFAULT),
    A100: var(--color-gray-600),
    A200: var(--color-gray-600),
    A400: var(--color-gray-600),
    A700: var(--color-gray-600)
  )
);

// Custom colors derived from Portal Style Guide 2021. TODO: Reference tailwind's colors directly here for consistency
$agent-portal-red: (
  50: var(--color-red-50),
  100: var(--color-red-100),
  200: var(--color-red-200),
  300: var(--color-red-300),
  400: var(--color-red-400),
  500: var(--color-red-500),
  600: var(--color-red-600),
  700: var(--color-red-700),
  800: var(--color-red-800),
  900: var(--color-red-900),
  contrast: (
    50: var(--color-gray-600),
    100: var(--color-gray-600),
    200: var(--color-gray-600),
    300: var(--color-gray-600),
    400: var(--color-gray-600),
    500: var(--color-white-DEFAULT),
    600: var(--color-white-DEFAULT),
    700: var(--color-white-DEFAULT),
    800: var(--color-white-DEFAULT),
    900: var(--color-white-DEFAULT),
    A100: var(--color-gray-600),
    A200: var(--color-gray-600),
    A400: var(--color-gray-600),
    A700: var(--color-gray-600)
  )
);

// mat.m2-define-palette(mainColorPalette, defaultShade, lightShade, darKShade);
// If no default, light & dark provided, default shades will be 500, 100, 700 from the provided color palette
$agent-portal-primary: mat.m2-define-palette($agent-portal-blue, 700, 100, 900);
$agent-portal-accent: mat.m2-define-palette($agent-portal-blue, 700, 100, 900);
$agent-portal-warn: mat.m2-define-palette($agent-portal-red);

// Create the different Material default text levels
$typography: mat.m2-define-legacy-typography-config(
  $font-family: "rawline",
  $display-4: mat.m2-define-typography-level(6.4rem, 1, 700),
  $display-3: mat.m2-define-typography-level(4.8rem, 1, 700),
  $display-2: mat.m2-define-typography-level(3.2rem, 1, 700),
  $display-1: mat.m2-define-typography-level(2.4rem, 1, 700),
  $headline: mat.m2-define-typography-level(1.7rem, 1.5, 700),
  $title: mat.m2-define-typography-level(1.7rem, 1.5, 700),
  $subheading-2: mat.m2-define-typography-level(1.5rem, 1.5, 500),
  $subheading-1: mat.m2-define-typography-level(1.1rem, 1.25, 700),
  $caption: mat.m2-define-typography-level(1.1rem, 1.5, 500),
  $button: mat.m2-define-typography-level(1.1rem, 1.25, 700),
  $body-1: mat.m2-define-typography-level(1.3rem, 1.5, 500),
  $body-2: mat.m2-define-typography-level(1.3rem, 1.5, 500)
);

$agent-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $agent-portal-primary,
      accent: $agent-portal-accent,
      warn: $agent-portal-warn
    ),
    typography: $typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($agent-portal-theme);
}

html {
  .mat-mdc-button {
    .mat-button-wrapper,
    .mdc-button__label {
      @apply flex items-center justify-center w-full;
    }
  }

  [mat-icon-button].mat-icon-button {
    @apply flex justify-center items-center;
  }

  //Mat select
  mat-select {
    .mat-mdc-select-trigger {
      @apply w-full h-full px-5;
    }
  }

  mat-option {
    .mat-mdc-option {
      @apply px-10 rounded text-base font-medium;
      @apply text-gray-600 dark:text-gray-200;
    }
  }

  // Mat menu
  .mat-mdc-menu-panel {
    @apply rounded-lg text-gray-dark dark:text-gray-lighter dark:bg-gray-darker;
    .mat-menu-content:not(:empty),
    .mat-mdc-menu-content {
      @apply flex flex-col p-10 gap-y-5;
    }

    .mat-mdc-menu-content,
    .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
      @apply text-left leading-normal;
    }

    .mat-mdc-menu-item .mat-icon-no-color,
    .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
      @apply text-gray;
    }
    .mat-mdc-menu-item:visited,
    .mat-mdc-menu-item:link {
      @apply text-gray-dark dark:text-gray-lighter;
    }
  }

  // Mat button toggle
  mat-button-toggle-group {
    @apply border border-solid border-gray-lighter h-34;

    .mat-button-toggle {
      @apply text-xs font-bold uppercase;
    }
    &:not([disabled]):not(.disabled) {
      .mat-button-toggle {
        @apply bg-white text-gray-dark;
        &.mat-button-toggle-checked {
          @apply bg-blue-dark text-white;
        }
        &.is-primary {
          @apply bg-white text-blue-dark;
          &.mat-button-toggle-checked {
            @apply bg-blue-dark text-white;
          }
        }
        &.is-warning {
          @apply bg-red-lightest text-red;
          &.mat-button-toggle-checked {
            @apply bg-red text-white;
          }
        }
      }
    }

    .mat-button-toggle {
      @apply ring-0;
      &:focus {
        @apply ring-0;
      }
      .mat-button-toggle-button {
        @apply min-w-72;
        .mat-button-toggle-label-content {
          line-height: 3.6rem;
        }
      }
    }
    .lifecycle-tab-btn {
      @apply max-w-128 overflow-hidden;
      .mat-button-toggle-label-content {
        @apply max-w-128 overflow-hidden text-ellipsis;
      }
    }
  }

  //Mat form item
  .mat-form-field {
    .mat-form-field-underline {
      @apply bg-gray-light;
    }
  }

  //Mat snack bar
  .mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      @apply font-semibold bg-white dark:bg-gray-darker;
    }

    .mdc-snackbar__label {
      @apply text-gray-dark dark:text-white;
    }

    &.is-success {
      .mdc-snackbar__label {
        @apply text-green dark:text-green-light;
      }
    }
    &.is-warning,
    &.is-error {
      .mdc-snackbar__label {
        @apply text-red dark:text-red-light;
      }
    }
  }

  //Mat dialogs
  mat-dialog-container.mat-mdc-dialog-container {
    @apply text-base font-body p-0 text-gray-dark flex rounded-lg;
    .mdc-dialog__container {
      @apply w-full;
    }
    .mdc-dialog__surface {
      @apply flex flex-col;
      & > * {
        @apply flex flex-col w-full max-h-full flex-grow;
      }
    }

    .dialog-toolbar {
      @apply min-h-0 bg-white text-gray-dark relative flex flex-col flex-grow-0 p-20 pb-0 border-b border-solid border-gray-lighter;
    }

    .dialog-content {
      @apply flex flex-col flex-grow overflow-auto h-full p-10 md:p-20;
      max-height: inherit;
    }

    header {
      @apply flex items-center flex-none p-10 md:p-20 border-b border-solid border-gray-lighter;
    }

    footer {
      @apply flex flex-none p-10 md:p-20 mt-auto border-t border-solid border-gray-lighter;
    }
  }

  //Sidenav & drawers
  .mat-drawer-container {
    @apply text-gray-dark dark:text-white dark:bg-gray-darkest bg-gray-lightest;
  }

  .mat-drawer {
    @apply bg-white dark:bg-gray-darker text-gray-dark dark:text-white;
  }

  //Mat expansion panel & accordion
  .mat-expansion-panel {
    @apply text-gray-dark;
    .mat-expansion-panel-body {
      @apply p-0;
    }
  }

  //CDK Drag-n-drop
  /* Animate items as they're being sorted. */
  .cdk-drop-list-dragging .cdk-drag {
    @apply transition-transform duration-300;
  }

  .cdk-drag-placeholder {
    @apply opacity-0;
  }

  .cdk-drag-animating {
    @apply transition-transform duration-300;
  }

  .cdk-drag {
    &.cdk-drag-preview {
      @apply shadow-lg;
    }
  }

  // Mat Datepicker
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
  .mat-calendar-body-selected {
    @apply text-white;
  }

  // Mat Drawer
  body {
    .mat-drawer-container {
      @apply text-gray-dark dark:text-gray-lighter bg-white dark:bg-gray-darkest;
    }
  }

  // Mat chip
  mat-chip-listbox,
  mat-chip-grid,
  mat-chip-set {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
      @apply text-gray-dark dark:text-gray-lighter text-sm;
    }
  }

  // Autocomplete
  div.mat-mdc-autocomplete-panel {
    @apply flex flex-col gap-y-5 p-10;
  }

  // Mat slide toggle
  .mat-mdc-slide-toggle {
    .mdc-label {
      @apply text-gray-dark dark:text-gray-lighter;
    }
  }

  // Mat checkbox
  mat-checkbox {
    .mdc-label {
      @apply text-gray-dark dark:text-gray-lighter;
    }
  }

  // Mat radio
  .mat-mdc-radio-button {
    .mdc-label {
      @apply text-gray-dark dark:text-gray-lighter;
    }
  }
}
